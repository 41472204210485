import { render, staticRenderFns } from "./AttactmentTab.vue?vue&type=template&id=43166462&scoped=true"
import script from "./AttactmentTab.vue?vue&type=script&lang=js"
export * from "./AttactmentTab.vue?vue&type=script&lang=js"
import style0 from "./AttactmentTab.vue?vue&type=style&index=0&id=43166462&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43166462",
  null
  
)

export default component.exports