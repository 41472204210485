<template>
  <div>
    <v-row class="px-4 product-row">
      <v-col md="12" class="px-0 py-0">
        <v-expansion-panels elevation="1" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="my-0 px-0 py-0">Logs</h5>
            </v-expansion-panel-header>
            <v-divider class="my-0"></v-divider>
            <v-expansion-panel-content class="px-0 py-0"> </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "log",
  data() {
    return {
      panel: [0],
      // product: {
      // }
    };
  },
};
</script>

<style scoped>
.th {
  font-size: 13px !important;
}

.product-row {
  margin: 0 !important;
}
</style>
