<template>
  <v-sheet>
    <Dialog :dialog="paymentVoucherDialog" card-text-class="px-4 py-0" :dialogWidth="1000">
      <template v-slot:title>
        <span>Edit Payment Voucher# {{ barcode }}{{ pv }}</span> <v-spacer></v-spacer
      ></template>

      <template v-slot:body>
        <!-- {{ $route.params.id }} -->
        <v-container>
          <template v-for="(item, index) in paymentVoucherData">
            <v-layout class="bg_grey pt-0 pb-0 px-0 my-0" :key="index.id">
              <v-row>
                <!-- <v-col md="3" class="py-1">
                    <label for="payment-made" class="field-label required">OTP</label>
                  <v-otp-input length="6"></v-otp-input>
                </v-col> -->
                <v-col md="12" class="py-1">
                  <label for="payment-made" class="field-label required">Password</label>
                  <TextInput
                    hide-detailspaymentVoucherDialog
                    :disabled="pageLoading"
                    v-on:keypress="(e) => manageLimit(e, index)"
                    v-on:paste="(e) => onPaste(e, index)"
                    :loading="pageLoading"
                    id="password"
                    placeholder="Password"
                    v-model="item.password"
                    class="mb-2"
                    :counter="10"
                  >
                  </TextInput>
                </v-col>

                <v-col md="12" class="py-1">
                  <label for="notes" class="field-label">Remark</label>
                  <TextAreaInput
                    v-model="item.remark"
                    auto-grow
                    v-on:keypress="(e) => manageLimits(e, index)"
                    v-on:paste="(e) => onPastes(e, index)"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="remark"
                  ></TextAreaInput>
                </v-col>
              </v-row>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="savePv"
        >
          Proceed
        </v-btn>
        <!-- v-on:click="$emit('save', false)" -->
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :dialog="pvConfirmDialog" card-text-class="px-4 py-0">
      <template v-slot:title> Confirmation</template>
      <template v-slot:body>
        <v-container>
          <h4 class="mt-4">Change to PV12</h4>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="savePv"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="pvConfirmDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import { SET_ERROR } from "@/core/services/store/common.module";
import { POST } from "@/core/services/store/request.module";

export default {
  name: "item-dialog",
  title: "Dialog Item",
  data() {
    return {
      excelLoading: null,
      paymentVoucherDialog: false,
      pvConfirmDialog: false,

      paymentVoucherData: [
        {
          password: "",
          remark: "",
        },
      ],
    };
  },
  watch: {
    dialog(params) {
      this.paymentVoucherDialog = params;
      this.$emit("saveContactPerson", params);
    },
  },
  methods: {
    manageLimit(e, index) {
      if (this.paymentVoucherData && this.paymentVoucherData[index]?.password.length > 9) {
        e.preventDefault();
      }
    },
    onPaste(e, index) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.paymentVoucherData[index].password;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 9) {
        let trimValue = finalval.substring(0, 10);
        this.paymentVoucherData[index].password = trimValue;
        e.preventDefault();
      }
    },
    manageLimits(e, index) {
      if (this.paymentVoucherData && this.paymentVoucherData[index]?.remark.length > 249) {
        e.preventDefault();
      }
    },
    onPastes(e, index) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.paymentVoucherData[index].remark;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 249) {
        let trimValue = finalval.substring(0, 250);
        this.paymentVoucherData[index].remark = trimValue;
        e.preventDefault();
      }
    },
    savePv() {
      console.log(this.paymentVoucherData, "paymentVoucherData");
      for (let i = 0; i < this.paymentVoucherData.length; i++) {
        if (
          this.paymentVoucherData &&
          this.paymentVoucherData[i] &&
          !this.paymentVoucherData[i]?.password
        ) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The password field is required" },
          ]);
          return false;
        }
      }
      this.$store
        .dispatch(POST, {
          url: `update-password-record`,
          data: {
            id: this.$route.params.id,
            data: this.paymentVoucherData,
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.paymentVoucherDialog = false;
        });
    },
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    barcode: {
      type: String,
      default: null,
    },
    pv: {
      type: String,
      default: null,
    },
  },
  components: {
    Dialog,
    TextInput,
    TextAreaInput,
  },
};
</script>
